import { useState, useEffect } from 'react';
import useMetaTags from 'react-metatags-hook'
import { Calendar as BookingCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './Calendar.module.css';
import { parseISOLocal, incDate } from "../helpers/dateHelpers";
import { getEvents } from "../api/googleCalendar";
import text from '../i8n/text.js';

function Calendar({ language }) {
	useMetaTags({
		title: text.calendar.meta.title[language],
		description: text.calendar.meta.description[language],
	});

	const [value, onChange] = useState(new Date());
	const [activeMonth, changeActiveMonth] = useState(value);

	const [bookings, changeBookings] = useState({
		booked: [],
		option: []
	});
	const [prices, changePrices] = useState({});

	const [enabledFrom, changeEnabledFrom] = useState(new Date());
	const [enabledUntil, changeEnabledUntil] = useState(new Date());

	const activeStartDateChange = ({ activeStartDate }) => {
		changeActiveMonth(activeStartDate);
	}

	const getDayClassName = (array, date) => {
		let result = '';
		for (let i = 0; i < array.length; i++) {
			if (array[i][0].getTime() === date.getTime()) {
				result = result === "end" ? "full" : "start";
			} else if (array[i][1].getTime() === date.getTime()) {
				result = "end";
			} else if (array[i][0].getTime() < date.getTime() && array[i][1].getTime() > date.getTime()) {
				result = "full";
			}
		}
		return result;
	}

	const getTileClassName = ({ date, view }) => {
		if (view === "month") {
			const priceKey = getPriceKey(date);
			const today = new Date();
			today.setHours(0,0,0,0);
			if (date < today ||
				(!prices[priceKey]) ||
				(enabledFrom && date < enabledFrom) ||
				(enabledUntil && date > enabledUntil)
			) {
				return 'disabled';
			} else {
				const bookedStatus = getDayClassName(bookings.booked, date);
				const optionStatus = getDayClassName(bookings.option, date);
				if (bookedStatus === 'full') {
					return styles.bookedFull;
				} else if (optionStatus === 'full') {
					return styles.optionFull;
				} else if (bookedStatus === 'start' && !optionStatus) {
					return styles.bookedStart;
				} else if (bookedStatus === 'end' && !optionStatus) {
					return styles.bookedEnd;
				} else if (optionStatus === 'start' && !bookedStatus) {
					return styles.optionStart;
				} else if (optionStatus === 'end' && !bookedStatus) {
					return styles.optionEnd;
				} else if (bookedStatus === 'start' && optionStatus === 'end') {
					return styles.optionEndBookedStart;
				} else if (optionStatus === 'start' && bookedStatus === 'end') {
					return styles.bookedEndOptionStart;
				} else {
					return styles.free;
				}
			}
		}
		return null;
	};

	const getPriceKey = date => `${date.getFullYear()}-${date.getMonth() + 1}`;

	const getPrices = (date, prices) => {
		let monthName = date.toLocaleString(language, { month: 'long' });
		monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
		const priceKey = getPriceKey(date);
		if (prices[priceKey]) {
			return (
				<div>
					<p className={styles.price}>
						{monthName} {date.getFullYear()} = €{prices[priceKey]}/{text.calendar.week[language]}
					</p>
				</div>
			);
		}
	};

	useEffect(() => {
		async function getGoogleCalendarEvents() {
			const timeMin = incDate(activeMonth, -80).toISOString();
			const timeMax = incDate(activeMonth, 80).toISOString();
			const events = await getEvents(timeMin, timeMax);
			const booked = [];
			const option = [];
			let newPrices = {};
			events.forEach(event => {
				const startDate = parseISOLocal(event.start.date);
				if (event.summary && event.summary.toUpperCase() === 'PRIJZEN') {
					try {
						newPrices[getPriceKey(startDate)] = JSON.parse(event.description).fw;
						if (startDate.getMonth() === activeMonth.getMonth()) {
							const dayFrom = JSON.parse(event.description).from;
							if (dayFrom) {
								changeEnabledFrom(incDate(activeMonth, dayFrom - 1));
							} else {
								changeEnabledFrom(null);
							}
							const dayUntil = JSON.parse(event.description).until;
							if (dayUntil) {
								changeEnabledUntil(incDate(activeMonth, dayUntil - 1));
							} else {
								changeEnabledUntil(null);
							}
						}
					} catch (error) {
						console.log('parse prices error', error, event.description)	;
					}
				} else {
					// set start and end date in local timezone
					let endDate = parseISOLocal(event.end.date);
					// end date is exclusive
					endDate = incDate(endDate, -1);
					// push event
					if (event.description && event.description.toUpperCase() === 'OPTIE') {
						option.push([startDate, endDate]);
					} else {
						booked.push([startDate, endDate]);
					}
				}
			});
			changeBookings({ booked, option });
			changePrices(newPrices);
		}
		getGoogleCalendarEvents();
	}, [activeMonth]);

	const pathSuffix = language === 'nl' ? '' : `_${language}`;
	return (
		<div>
			<img className={styles.topImage} src="./images/sfeer_15_top.jpg" alt="" />
			<div className={styles.container}>
				<div className={styles.calendarContainer}>
					<div className={styles.calendar}>
						<BookingCalendar
							onChange={onChange}
							value={value}
							onActiveStartDateChange={activeStartDateChange}
							tileClassName={getTileClassName}
							minDate={new Date()}
							locale={language}
						/>
					</div>
				</div>
				<div className={styles.monthInfo}>
					{getPrices(activeMonth, prices)}
				</div>
				<div className={styles.remarks}>
					<ul>
						<li>{text.calendar.week.description[language]}</li>
						<li>{text.calendar.arrival[language]}</li>
						<li>{text.calendar.departure[language]}</li>
						<li>{text.calendar.utilities[language]}</li>
						<li>{text.calendar.cleaning[language]}</li>
						<li>{text.calendar.towels[language]}</li>
						<li>{text.calendar.animals[language]}</li>
						<li>{text.calendar.specials[language]}</li>
						<li>{text.calendar.discount[language]}</li>
						<li>{text.calendar.contact[language]}</li>
					</ul>
					<div className={styles.bookLink}>
						<a href={`./contact${pathSuffix}`}>{text.calendar.book[language]}</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Calendar;