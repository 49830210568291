import useMetaTags from 'react-metatags-hook'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaPredicate } from "react-media-hook";
import text from '../i8n/text.js';

function Home({ language }) {
  useMetaTags({
    title: text.home.meta.title[language],
    description: text.home.meta.description[language],
  });
	const isSmall = useMediaPredicate("(max-width: 600px)");
	return ( 
	  <div>
  		<Carousel infiniteLoop autoPlay dynamicHeight interval={5000} showThumbs={false} transitionTime={1000} stopOnHover={false} showStatus={false}>
        <div>
          <img className="image-1" src={isSmall ? "./images/buiten_05.jpg" : "./images/buiten_05_main.jpg"} alt="" />
					{/*<p className={styles.carouselText}>{text.home.pic1[language]}</p>*/}
        </div>
        <div>
          <img className="image-2" src={isSmall ? "./images/buiten_01.jpg" : "./images/buiten_01_main.jpg"} alt="" />
		      {/*<p className={styles.carouselText}>{text.home.pic2[language]}</p>*/}
        </div>
        <div>
          <img className="image-3" src={isSmall ? "./images/buiten_03.jpg" : "./images/buiten_03_main.jpg"} alt="" />
		      {/*<p className={styles.carouselText}>{text.home.pic3[language]}</p>*/}
        </div>
      </Carousel>
	 	</div>
  );
}

export default Home;