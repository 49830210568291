import styles from './Description.module.css';
import useMetaTags from 'react-metatags-hook'
import text from "../i8n/text";

function Description({ language }) {
	useMetaTags({
		title: text.description.meta.title[language],
		description: text.description.meta.description[language],
	});
	const getPlan = (labelGroundFirst, labelPoolhouse) => (
		<div id="main_text_description_plan" className={styles.planContainer}>
			<div className={styles.plan}>
				<a href="./images/FLM - ground floor and first floor.pdf" target="_blank">
					<img src="./images/plan_1.jpg" alt="" />
					{labelGroundFirst}
				</a>
			</div>
			<div className={styles.plan}>
				<a href="./images/FLM - poolhouse.pdf" target="_blank">
					<img src="./images/plan_2.jpg" alt="" />
					{labelPoolhouse}
				</a>
			</div>
		</div>
	);

	const getDescription = () => {
		if (language === 'nl') {
			return(
				<div className={styles.description}>
					<p>Het huis biedt met zijn <strong>± 300 m²</strong> een mooie inkomhal/zitruimte met open haard, een ruime eetkamer (10 personen) met houtkachel die overgaat in een ruime zithoek met <strong>karaktervolle open haard</strong>, een authentieke volledig ingerichte keuken, <strong>4 ruime slaapkamers</strong> (3 dubbele en 1 met 2 stapelbedden – 2 op het gelijkvloers en 2 op de verdieping), <strong>1 badkamer, 2 douchekamers</strong>, 3 toiletten. Dit alles wordt aangevuld met verschillende terrassen (naya met traditionele broodoven, buitenterras en overdekte buitenkeuken met BBQ) en een <strong>groot zwembad </strong>(5 x 10 m + kleedkamer met douche, toilet en wasplaats met wasmachine) en een volwassen aangename tuin.</p>
					<p>Voor het huis is er <strong>voldoende parkeerruimte tot 5 wagens</strong>.</p>
					<p>Andere eigenschappen: Wifi, satelliet-TV (TV Vlaanderen), 1 kinderbed en kinderstoel voorhanden, stijlvol ingericht in finca-stijl, op minder dan 10 minuten van verschillende stranden, restaurant “Pedramala” en supermarktje “Perles” op wandelafstand.</p>
					<p>Verhuur steeds van zaterdag tot zaterdag.</p>
					<p>Water, elektriciteit en gas inbegrepen.</p>
					<p>Opkuis en lakenpakket exclusief.</p>
					<p>Toeristische licentie VT-465969-A</p>
					{getPlan('Gelijkvloers en eerste verdieping - PDF', 'Poolhouse - PDF')}
				</div>)
		} else if (language === 'fr') {
			return(
				<div className={styles.description}>
					<p>La maison de <strong>± 300 m²</strong> offre un beau hall d’entrée/salon avec feu ouvert, une salle à manger spacieuse (10 personnes) avec poêle à bois qui donne sur le grand salon orné d’une <strong>cheminée d’époque</strong>, une cuisine équipée authentique, <strong>4 chambres à coucher</strong> (3 double et 1 avec deux lits superposés – 2 au rdc et 2 au 1er étage), <strong>1 salle de bains</strong>, <strong>2 salles de douches</strong>, 3 toilettes. Le tout bordé par plusieurs terrasses (naya avec four à pain traditionnel, terrasse sur le côté et cuisine extérieure couverte avec BBQ) et une <strong>grande piscine</strong> de 5 x 10 m (+ vestiaire avec douche, toilette et coin buanderie avec machine) et un jardin agréable et mature. </p>
					<p>Possibilité de <strong>garer jusqu’à 5 voitures</strong> devant la maison. </p>
					<p>Divers : Wifi, satellite (TV Vlaanderen), 1 lit et chaise bébé à disposition, décoration style finca, à moins de 10 minutes de plusieurs plages, restaurant “Pedramala” et supermarché “Perles” accessible à pied. </p>
					<p>Location toujours du samedi au samedi.</p>
					<p>Eau, électricité et gaz inclus.</p>
					<p>Forfait ménage et linge exclusif.</p>
					<p>Licence touristique VT-465969-A</p>
					{getPlan('Rez-de-chaussée et premier étage - PDF', 'Poolhouse - PDF')}
				</div>)
		} else if (language === 'en') {
			return(
				<div className={styles.description}>
					<p>The house of <strong>± 300 m²</strong> consists of a beautiful entrance hall/sitting area with <strong>fire place</strong>, a spacious dining room (10 people) with wood stove, giving onto the sitting area with charmingly old fire place, an authentic fully equipped kitchen, <strong>4 comfortable bedrooms</strong> (3 double and 1 with 2 bunk beds – 2 on ground level and 2 on first floor), <strong>1 bathroom</strong>, <strong>2 shower rooms</strong>, 3 toilets. This is topped with several terraces (naya with old bread oven, outside terrace on the side and covered outdoor kitchen with BBQ) and a <strong>large swimming pool</strong> (5 x 10 m + dressing room with shower, toilet and laundry corner with washer) and a pleasant and mature garden. </p>
					<p>In front of the house there is enough <strong>space for up to 5 cars</strong>. </p>
					<p>Other features: Wifi, satellite TV, 1 baby bed and seat present, finca style décor, less than 10 minutes away for several nice beaches, restaurant “Pedramala” and supermarket “Perles” at walking distance. </p>
					<p>Rental always from Saturday to Saturday.</p>
					<p>Water, electricity and gas included.</p>
					<p>Cleaning and linen package exclusive.</p>
					<p>Tourist license VT-465969-A</p>
					{getPlan('Ground floor and first floor - PDF', 'Poolhouse - PDF')}
				</div>)
		}else if (language === 'de') {
			return(
				<div className={styles.description}>
					<p>Das Haus, mit seinen <strong>± 300 m²</strong>, bietet eine Eingangshalle/Lounge mit Kamin, ein geräumiges Esszimmer (10 Personen) mit Holzofen, eine gemütliche Sitzecke mit <strong>Original-Kamin</strong>, eine authentische völlig ausgestattete Küche, <strong>4 Schlafzimmer</strong> (3 Doppelzimmer und 1 mit 2 Etagenbetten - 2 im Erdgeschoss und 2 im Obergeschoss),  <strong>1 Badezimmer, 2 Duschezimmer</strong>, 3 Toiletten.  All dies wird ergänzt mit mehreren Terrassen (Naya mit alten Brotbackofen, eine Außenterrasse und überdachte Außenküche mit Grill), mit <strong>einem großen Pool</strong> (+ Umkleideraum met Dusche, Toilette und Waschraum mit Waschmaschine) und einem schönen Garten.</p>
					<p>Ausreichend <strong>Parkplätze für 5 Autos</strong>.
						Andere Merkmale: Wifi, Satelliten-TV, 1 Kinderbett und -Stuhl, Finca-Dekor, weniger als 10 Minuten von verschiedenen Stränden entfernt, Restaurant “Pedramala” (Deutsche Eigentümer) und Supermarkt “Perles” erreichbar zu Fuß.</p>
					<p>Vermietung immer von Samstag bis Samstag.</p>
					<p>Wasser, Strom und Gas inklusive.</p>
					<p>Endreinigung und Wäschepaket exklusiv.</p>
					<p>Touristenlizenz VT-465969-A</p>
					{getPlan('Erdgeschoss und erster Stock - PDF', 'Poolhaus - PDF')}
				</div>)
		} else if (language === 'es') {
			return(
				<div className={styles.description}>
					<p>La casa ofrece, con sus <strong>± 300 m²</strong> un hall de entrada/sala de estar con <strong>chimenea</strong>, un comedor (10 personas) con estufa de leña, un amplio salón con chimenea de leña, auténtica cocina totalmente equipada, <strong>4 habitaciones</strong> (3 dobles y 1 con 2 literas) - 2 abajo y 2 arriba, <strong>1 baño</strong>, <strong>2 cuartos de ducha</strong>, 3 aseos. Todo esto se complementa con varias terrazas (naya con horno de pan, terraza al aire libre y cocina cubierta al aire libre con barbacoa y <strong>piscina</strong> (5 x 10) + vestidor con ducha, aseo y lavadero con lavadora y un jardín agradable. </p>
					<p>Delante de la casa hay un <strong>amplio aparcamiento para 5 coches</strong>. </p>
					<p>Otras características: WiFi, TV vía satélite, 1 cuna y silla para bebé, elegantemente decorado  en estilo finca, a menos de 10 minutos de varias playas, restaurante "Pedramala" y mini supermercado "Perles" accesible a pie.</p>
					<p>Alquiler siempre de sábado a sábado.</p>
					<p>Agua, luz y gas incluidos</p>
					<p>Paquete de limpieza y ropa de cama exclusivo.</p>
					<p>Licencia turística VT-465969-A</p>
					{getPlan('Planta baja y primer piso - PDF', 'Casa de piscina - PDF')}
				</div>)
		}
	}
	
	return ( 
	  <div>
		  <img className={styles.topImage} src="./images/buiten_19_top.jpg" alt="" />
			{getDescription()}
	 	</div>
  );
}

export default Description;