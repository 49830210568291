import React from 'react';
import useMetaTags from 'react-metatags-hook'
import styles from './Video.module.css';
import text from '../i8n/text.js';

function Video({ language }) {
	useMetaTags({
		title: text.pictures.meta.title[language],
		description: text.pictures.meta.description[language],
	});
	return (
		<div className={styles.video}>
			<iframe
				src="https://www.youtube.com/embed/-Wqkx90qM7o?rel=0"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen>
			</iframe>
		</div>
  );
}

export default Video;