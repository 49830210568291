import useMetaTags from 'react-metatags-hook'
import styles from './Links.module.css'
import text from "../i8n/text";

function Links({ language }) {
  useMetaTags({
  	title: text.links.meta.title[language],
  	description: text.links.meta.description[language],
  });

  const getLinks = () => {
    return (
      <div className={styles.container}>
        {text.links.flights.title[language]} :
        <ul>
          {text.links.flights.links.map(link => (
            <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer">{link.name}</a></li>
          ))}
        </ul>
        {text.links.carRental.title[language]} :
        <ul>
          {text.links.carRental.links.map(link => (
            <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer">{link.name}</a></li>
          ))}
        </ul>
        {text.links.pointsOfInterest.title[language]} :
        <ul>
          {text.links.pointsOfInterest.links.map(link => (
            <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer">{link.name}</a></li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div>
      <img className={styles.topImage} src="./images/buiten_19_top.jpg" alt="" />
      {getLinks()}
    </div>
  );
}

export default Links;