import React from 'react';
import useMetaTags from 'react-metatags-hook'
import Gallery from 'react-grid-gallery';
import { useMediaPredicate } from "react-media-hook";
import './Pictures.module.css';
import text from '../i8n/text.js';

function Pictures({ language }) {
	useMetaTags({
		title: text.pictures.meta.title[language],
		description: text.pictures.meta.description[language],
	});
	const isSmall = useMediaPredicate("(max-width: 600px)");

	const thumbnailWidth = isSmall ? 200 : 320;
	const thumbnailHeight = isSmall ? 150 : 212;
	
	const IMAGES = [
		{
			src: "./images/buiten_01.jpg",
			thumbnail: "./images/buiten_01_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_02.jpg",
			thumbnail: "./images/buiten_02_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_03.jpg",
			thumbnail: "./images/buiten_03_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
    },
		{
			src: "./images/buiten_04.jpg",
			thumbnail: "./images/buiten_04_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_05.jpg",
			thumbnail: "./images/buiten_05_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_06.jpg",
			thumbnail: "./images/buiten_06_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
    },
		{
			src: "./images/buiten_07.jpg",
			thumbnail: "./images/buiten_07_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_08.jpg",
			thumbnail: "./images/buiten_08_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_19.jpg",
			thumbnail: "./images/buiten_19_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_09.jpg",
			thumbnail: "./images/buiten_09_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_10.jpg",
			thumbnail: "./images/buiten_10_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
    },
		{
			src: "./images/buiten_11.jpg",
			thumbnail: "./images/buiten_11_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_12.jpg",
			thumbnail: "./images/buiten_12_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_13.jpg",
			thumbnail: "./images/buiten_13_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
    },
		{
			src: "./images/buiten_14.jpg",
			thumbnail: "./images/buiten_14_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_15.jpg",
			thumbnail: "./images/buiten_15_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_16.jpg",
			thumbnail: "./images/buiten_16_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
    },
		{
			src: "./images/buiten_17.jpg",
			thumbnail: "./images/buiten_17_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/buiten_18.jpg",
			thumbnail: "./images/buiten_18_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/binnen_01.jpg",
			thumbnail: "./images/binnen_01_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_02.jpg",
			thumbnail: "./images/binnen_02_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_03.jpg",
			thumbnail: "./images/binnen_03_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_04.jpg",
			thumbnail: "./images/binnen_04_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_05.jpg",
			thumbnail: "./images/binnen_05_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_06.jpg",
			thumbnail: "./images/binnen_06_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_07.jpg",
			thumbnail: "./images/binnen_07_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_08.jpg",
			thumbnail: "./images/binnen_08_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "./images/binnen_09.jpg",
			thumbnail: "./images/binnen_09_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/binnen_10.jpg",
			thumbnail: "./images/binnen_10_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.kitchen[language]
		},
		{
			src: "./images/binnen_11.jpg",
			thumbnail: "./images/binnen_11_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.kitchen[language]
		},
		{
			src: "./images/binnen_12.jpg",
			thumbnail: "./images/binnen_12_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom3[language]
		},
		{
			src: "./images/binnen_13.jpg",
			thumbnail: "./images/binnen_13_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bathroom2[language]
		},
		{
			src: "./images/binnen_14.jpg",
			thumbnail: "./images/binnen_14_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom4[language]
		},
		{
			src: "./images/binnen_15.jpg",
			thumbnail: "./images/binnen_15_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bathroom3[language]
		},
		{
			src: "./images/binnen_16.jpg",
			thumbnail: "./images/binnen_16_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom1[language]
		},
		{
			src: "./images/binnen_17.jpg",
			thumbnail: "./images/binnen_17_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bathroom1[language]
		},
		{
			src: "./images/binnen_18.jpg",
			thumbnail: "./images/binnen_18_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom2[language]
		},
		{
			src: "./images/sfeer_01.jpg",
			thumbnail: "./images/sfeer_01_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_02.jpg",
			thumbnail: "./images/sfeer_02_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_03.jpg",
			thumbnail: "./images/sfeer_03_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_04.jpg",
			thumbnail: "./images/sfeer_04_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_05.jpg",
			thumbnail: "./images/sfeer_05_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_06.jpg",
			thumbnail: "./images/sfeer_06_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_07.jpg",
			thumbnail: "./images/sfeer_07_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_08.jpg",
			thumbnail: "./images/sfeer_08_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_09.jpg",
			thumbnail: "./images/sfeer_09_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_10.jpg",
			thumbnail: "./images/sfeer_10_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_11.jpg",
			thumbnail: "./images/sfeer_11_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_12.jpg",
			thumbnail: "./images/sfeer_12_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_13.jpg",
			thumbnail: "./images/sfeer_13_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_14.jpg",
			thumbnail: "./images/sfeer_14_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_15.jpg",
			thumbnail: "./images/sfeer_15_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_16.jpg",
			thumbnail: "./images/sfeer_16_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_17.jpg",
			thumbnail: "./images/sfeer_17_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
		{
			src: "./images/sfeer_18.jpg",
			thumbnail: "./images/sfeer_18_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
		},
	];

	return (
		 <Gallery
			 className="container"
			 images={IMAGES}
			 enableImageSelection={false}
			 backdropClosesModal={true}
			 rowHeight={isSmall ? 109 : 200}
		 />
  );
}

export default Pictures;